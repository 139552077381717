<template>
    <div class="wrapper">
        <router-view name="media_container" ref="media-container"/>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: "MediaContainer",
    data() {
		return {
		}
	},
    computed: {
		...mapGetters('helper', ['labels']),
		...mapGetters('media', ['countContentChanges']),
        ...mapState('media', ['stateSingleData']),
		pageHeaderData() {
			let output = {
				preTitle: this.stateSingleData?.title ? this.labels.media : '',
				title: this.stateSingleData?.title ? this.stateSingleData?.title : '',
				btnList: [
					{
						type: 'button',
						label: this.labels.save,
						variant: 'btn-green',
						class: 'btn-type-button',
						action: this.saveAction,
						disabled: this.countContentChanges.saveBtnDisabled
					}
				]
			}
			return output;
		}
	},
    methods: {
        ...mapMutations('helper', ['setPageHeaderData']),
		...mapMutations('media', ['clearSingleData']),
        ...mapActions('notification', ['addNotification']),
        ...mapActions('media', ['getSingle', 'update']),
        ...mapGetters('media', ['createMediaData']),
        getSingleData() {
            this.getSingle( this.$route.params.id )
			.then( res => {
				if (res.success) {
					this.loopKey = Date.now();
				} else if (res.error) {
					this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
				}
			})
			.catch( err => console.log( err ) );
		},
        saveAction() {
			let payload = this.stateSingleData;

			if (typeof payload.media !== 'string') {
				// Media is not just a string anymore, user selected new file, creating payload as a form now
				payload = new FormData();

				payload.append('_id', this.$route.params.id);
				payload.append('title', this.stateSingleData.title);
				payload.append('text', this.stateSingleData.text);
				payload.append('media', this.stateSingleData.media.fileToUpload);
			}

			this.clearSingleData();

			this.update( payload )
			.then( res => {
				if (res.success) {
					this.addNotification( { variant: 'success', msg: this.labels.success });
					this.getSingleData();
				} else if (res.error) {
					this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
				}
			})
			.catch( err => console.log( err ) );
		},
    },
    beforeMount() {
		this.setPageHeaderData( { preTitle: '', title: '', btnList: [] } );
	},
	mounted() {
        this.getSingleData();
		this.setPageHeaderData(this.pageHeaderData);
	},
	beforeDestroy() {
		this.setPageHeaderData({});
	},
	watch: {
		pageHeaderData( value ) {
			this.setPageHeaderData( value );
		},
	},
}
</script>

<style lang="scss" scoped>

</style>